import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetOverlappingData, useSetOverlappingData } from "../../../apis/studyApi";
import * as S2 from "../../../elements/Button/style/ButtonLayout.style";
import DragImage from "../../../elements/Drop/DragImage";
import * as S from "../../../elements/Drop/style/Drop.style";
import { IK } from "../../../utils/i18n/keyStore";
import { ADDITIONAL_OVERLAP_DATA } from "../../../utils/studyStatus";
import { CardTail } from "../../layout/CardLayout/CardLayout";

const StudyViewOverlap = ({ additionalId, studyId, patientId }) => {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading } = useGetOverlappingData(additionalId);
  const overlapData = data?.data;

  /**reduce로 사진 셋팅 */
  const [overlapSetting, setOverlapSetting] = useState(true);

  const { handleSubmit, setValue } = useForm();

  /**비교중첩 데이터 담기 */
  const [overlapObj, setOverlapObj] = useState({});

  useEffect(() => {
    if (!isLoading && overlapData?.length > 0) {
      const overlaps = overlapData.reduce((acc, curr) => {
        acc[curr.position] = curr;
        return acc;
      }, {});
      setOverlapObj(overlaps);
    }
    setOverlapSetting(false);
  }, [isLoading, overlapData]);

  /**파일 삭제 */
  const [deleteFileIds, setDeleteFileIds] = useState([]);

  /**비교중첩 데이터 등록 */
  const overlapMutation = useSetOverlappingData();

  /**비교중첩 데이터 등록 요청 */
  const handleoverlapSubmit = (data) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      const isEmptyValue = !value || (Array.isArray(value) && value.length === 0);
      const isDuplicateFile = value && value.additionalFileId;

      if (!isEmptyValue && !isDuplicateFile) {
        formData.append(key, value);
      }
    }

    formData.append("deleteAdditionalFileIds", deleteFileIds.join(","));

    const payload = {
      additionalId,
      formData,
      params: {
        studyId,
        patientId,
      },
    };

    overlapMutation.mutate(payload, {
      onSuccess: () => {
        setDeleteFileIds([]);
      },
    });
  };

  return (
    <>
      {isLoading || overlapSetting ? (
        <Skeleton height={500} />
      ) : (
        <form onSubmit={handleSubmit(handleoverlapSubmit)}>
          <S.DropListWrap $overlap>
            {ADDITIONAL_OVERLAP_DATA.map((img) => (
              <S.DropList className={`${img.classImg}`} key={img.classImg}>
                <DragImage setValue={setValue} name={`${img.fileImg}`} deleteFileIds={deleteFileIds} setDeleteFileIds={setDeleteFileIds} files={overlapObj[`${img.fileImg}`]} />
              </S.DropList>
            ))}
          </S.DropListWrap>

          <CardTail line>
            <S2.ButtonLtBox>
              <S2.StyledButton as="button" type="button" onClick={() => navigate(`/studyList${location?.state?.herf || "?page=1"}`)}>
                {t(IK.list)}
              </S2.StyledButton>
              <S2.StyledButton as="button" type="submit" $primary>
                {t(IK.upload)}
              </S2.StyledButton>
            </S2.ButtonLtBox>
          </CardTail>
        </form>
      )}
    </>
  );
};

export default StudyViewOverlap;
