import { IK } from "./i18n/keyStore";

export const clinicalPackages = {
  "": "None",
  R: "R-active",
  RP: "R-passive",
  S10: "S10",
  S20: "S20",
  SR: "Regular",
  SAP: "Regular AP",
  Si: "i",
  SiAP: "iAP",
};

/** 임상증례 임상상태 */
export const clinicConditionCheckList = [
  [
    { label: "Class I", value: "class1" },
    { label: "Class II div 1", value: "class2" },
    { label: "Class II div 2", value: "class3" },
    { label: "Class III", value: "class4" },
    { label: IK.crowding, value: "crowding" },
    { label: IK.Interdental_space, value: "spacing" },
    { label: IK.gingival_recession, value: "gingivalRecession" },
  ],
  [
    { label: IK.narrow_dental_arch, value: "narrowArch" },
    { label: IK.deepbite, value: "deepBite" },
    { label: IK.open_bite_vertical, value: "openbiteVerticality" },
    { label: IK.open_bite_horizontal, value: "openbiteOverjet" },
    { label: IK.anterior_crossbite, value: "abteriorCrossbite" },
    { label: IK.posterior_crossbite, value: "posteriorCrossbite" },
    { label: IK.scissors_bite, value: "scissorsBite" },
  ],
  [
    { label: IK.prolapse_of_anterior_teeth, value: "flaredTeeth" },
    { label: IK.smile_line_needs_improvement, value: "unevenSmile" },
    { label: IK.asymmetry, value: "asymmetric" },
    { label: IK.Inclined_occlusal_plane_right, value: "occlusalPlaneRight" },
    { label: IK.Inclined_occlusal_plane_left, value: "occlusalPlaneLeft" },
    { label: IK.missing, value: "missing" },
    { label: IK.abnormally_shaped_teeth, value: "misshapenTeeth" },
  ],
];

/**논세라핀 장치 */
export const nonSerafinDeviceList = {
  TADs: "TADs",
  MARPE: "MARPE",
  Every10: "Every10",
  empty: "empty",
};

/**세라핀 장치 */
export const serafinDeviceList = {
  FixedBonded: "Fixed/Bonded",
  ClearAligner: "Clear aligner",
  WrapAround: "Wrap around",
  Hawley: "Hawley",
};

/** Results achieved 항목 */
export const achievedList = {
  "Class I relationship achieved": "ClassRelationshipAchieved",
  "Class I relationship maintained": "ClassRelationshipMaintained",
  "Functional canine guidance on both sides": "FunctionalCanineGuidanceOnBothSides",
  "Anterior crossbite corrected": "AnteriorCrossbiteCorrected",
  "Posterior crossbite corrected": "PosteriorCrossbiteCorrected",
  "Deepbite improved": "DeepbiteImproved",
  "Openbite improved": "OpenbiteImproved",
  "Normal overjet and overbite achieved": "NormalOverjetAndOverbiteAchieved",
  "Overjet improved": "OverjetImproved",
  "Overbite improved": "OverbiteImproved",
  "Significant improvement of overjet": "SignificantImprovementOfOverjet",
  "Significant improvement of overbite": "SignificantImprovementOfoverbite",
  "Midlines coincident": "MidlinesCoincident",
  "Proper axial inclination of incisors": "ProperAxialInclinationOfIncisors",
  "Curve of Spee leveled": "CurveOfSpeeLeveled",
  "Arches aligned and coordinated": "ArchesAlignedAndCoordinated",
  "Alignment of both arches through derotation": "AlignmentOfBothArchesThroughDerotation",
  "Arch form improved": "ArchFormImproved",
  "Arch expansion achieved": "ArchExpansionAchieved",
  "Aesthetic smile line was achieved": "AestheticSmileLineWasAchieved",
  "All treatment goals were achieved case": "AllTreatmentGoalsWereAchievedCase",
  "Harmonic arches were achieved": "HarmonicArchesWereAchieved",
};

export const iDropImages = [
  { style: "occlusal_upper", name: "i_occlusal_upper" },
  { style: "intraoral_front", name: "i_intraoral_front" },
  { style: "occlusal_lower", name: "i_occlusal_lower" },
  { style: "buccal_right", name: "i_buccal_right" },
  { style: "overjet", name: "i_overjet" },
  { style: "buccal_left", name: "i_buccal_left" },
];

export const pDropImages = [
  { style: "occlusal_upper", name: "p_occlusal_upper" },
  { style: "intraoral_front", name: "p_intraoral_front" },
  { style: "occlusal_lower", name: "p_occlusal_lower" },
  { style: "buccal_right", name: "p_buccal_right" },
  { style: "overjet", name: "p_overjet" },
  { style: "buccal_left", name: "p_buccal_left" },
];

export const fDropImages = [
  { style: "occlusal_upper", name: "f_occlusal_upper" },
  { style: "intraoral_front", name: "f_intraoral_front" },
  { style: "occlusal_lower", name: "f_occlusal_lower" },
  { style: "buccal_right", name: "f_buccal_right" },
  { style: "overjet", name: "f_overjet" },
  { style: "buccal_left", name: "f_buccal_left" },
];

export const iRadiation = [
  { style: "panorama", name: "i_panorama" },
  { style: "cephalo", name: "i_cephalo" },
];

export const fRadiation = [
  { style: "panorama", name: "f_panorama" },
  { style: "cephalo", name: "f_cephalo" },
];
