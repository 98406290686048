import { TEETH_SELECT_ARRAY } from "../../utils/studyStatus";
import teethLabelJson from "../../utils/TeethLabel.json";
import * as S from "./style/TeethTable.style";

/**치아 키값 찾아내기 */
function getTeethKeyFromLabel(label) {
  const keysOfTeeth = Object.keys(teethLabelJson);
  return keysOfTeeth.find((key) => teethLabelJson[key] === label);
}

/**치아분류 하기 */
function getTeethLabelList(label, age) {
  let teeth_label = [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38];

  if (label === "permanent" && age === "child") {
    teeth_label = [16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36];
  } else if (label === "permanent_predict") {
    teeth_label = [15, 14, 13, 23, 24, 25, 45, 44, 43, 33, 34, 35];
  }

  return teeth_label;
}

/**치아박스 렌더링 */
function TeethSummaryBox({ studyInfo, label, config = TEETH_SELECT_ARRAY, code }) {
  const teeth_label = getTeethLabelList(label, studyInfo.study.age);

  /**자르는 선 계산용 */
  const sliceNumber = teeth_label.length / 4;

  /**어린이면서 영구치 */
  const isChildPerment = label === "permanent" && studyInfo.study.age === "child";
  const excludeTeethNumberWhenIsChild = ["1", "2", "15", "16", "17", "18", "31", "32"];
  const filteredConfig = isChildPerment || label === "permanent_predict" ? Object.entries(config).filter(([key]) => !excludeTeethNumberWhenIsChild.includes(key)) : Object.entries(config);

  const babyTeethValues = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "T", "S", "R", "Q", "P", "O", "N", "M", "L", "K"];
  const babyTeeth_label = [55, 54, 53, 52, 51, 61, 62, 63, 64, 65, 85, 84, 83, 82, 81, 71, 72, 73, 74, 75];

  /**자르는 선 계산용 */
  const sliceNumberBaby = babyTeeth_label.length / 4;

  /**readOnly 속성 걸기 */
  const onCheckReacOnly = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <>
      <S.TeethContainer>
        <S.TeethDirection>R</S.TeethDirection>
        <S.TeethTable>
          <tbody>
            {(!studyInfo.SI01 || studyInfo.SI01 === "1" || studyInfo.SI01 === "2") && (
              <tr>
                <td>
                  <div>
                    {(studyInfo.SI01 === "1" || studyInfo.SI01 === "2") && label === "permanent_predict" && (
                      <S.TeethItem>
                        <S.TeethItemLabel>
                          <S.TeethItemInput $TeethRadio type="checkbox" onClick={onCheckReacOnly} checked={studyInfo.SI14_01 === "2" && studyInfo.SI14_02_2_01?.includes("2")} readOnly />
                          <S.TeethItemSpan>17</S.TeethItemSpan>
                        </S.TeethItemLabel>
                      </S.TeethItem>
                    )}
                    {filteredConfig
                      .slice(0, sliceNumber)
                      .map(
                        ([key, value], index) => value && <TeethSummaryCheckItem key={key} value={key} itemName={`${code}_2_01`} TeethCheckbox teethLabel={teeth_label[index]} studyInfo={studyInfo} />
                      )}
                  </div>
                  {studyInfo.study.age === "child" && (
                    <div>
                      {filteredConfig
                        .slice(0, sliceNumberBaby)
                        .map(
                          ([key, value], index) =>
                            value && <TeethSummaryCheckItem key={key} value={babyTeethValues[index]} itemName={`${code}_2_02`} teethLabel={babyTeeth_label[index]} TeethRadio studyInfo={studyInfo} />
                        )}
                    </div>
                  )}
                </td>
                <td>
                  <div>
                    {filteredConfig.slice(sliceNumber, sliceNumber * 2).map(([key, value], index) => {
                      return value && <TeethSummaryCheckItem key={key} value={key} itemName={`${code}_2_01`} TeethCheckbox teethLabel={teeth_label[sliceNumber + index]} studyInfo={studyInfo} />;
                    })}
                    {(studyInfo.SI01 === "1" || studyInfo.SI01 === "2") && label === "permanent_predict" && (
                      <S.TeethItem>
                        <S.TeethItemLabel>
                          <S.TeethItemInput $TeethRadio type="checkbox" onClick={onCheckReacOnly} checked={studyInfo.SI14_01 === "2" && studyInfo.SI14_02_2_02?.includes("15")} readOnly />
                          <S.TeethItemSpan>27</S.TeethItemSpan>
                        </S.TeethItemLabel>
                      </S.TeethItem>
                    )}
                  </div>
                  {studyInfo.study.age === "child" && (
                    <div>
                      {filteredConfig.slice(sliceNumberBaby, sliceNumberBaby * 2).map(([key, value], index) => {
                        return (
                          value && (
                            <TeethSummaryCheckItem
                              key={key}
                              value={babyTeethValues[sliceNumberBaby + index]}
                              itemName={`${code}_2_02`}
                              TeethRadio
                              teethLabel={babyTeeth_label[sliceNumberBaby + index]}
                              studyInfo={studyInfo}
                            />
                          )
                        );
                      })}
                    </div>
                  )}
                </td>
              </tr>
            )}

            {(!studyInfo.SI01 || studyInfo.SI01 === "1" || studyInfo.SI01 === "3") && (
              <tr>
                <td>
                  {studyInfo.study.age === "child" && (
                    <div>
                      {filteredConfig
                        .slice(sliceNumberBaby * 2, sliceNumberBaby * 3)
                        .map(
                          ([key, value], index) =>
                            value && (
                              <TeethSummaryCheckItem
                                key={key}
                                value={babyTeethValues[sliceNumberBaby * 2 + index]}
                                itemName={`${code}_2_02`}
                                teethLabel={babyTeeth_label[sliceNumberBaby * 2 + index]}
                                TeethRadio
                                studyInfo={studyInfo}
                              />
                            )
                        )}
                    </div>
                  )}
                  <div>
                    {(studyInfo.SI01 === "1" || studyInfo.SI01 === "3") && label === "permanent_predict" && (
                      <S.TeethItem>
                        <S.TeethItemLabel>
                          <S.TeethItemInput $TeethRadio type="checkbox" onClick={onCheckReacOnly} checked={studyInfo.SI14_01 === "2" && studyInfo.SI14_02_2_03?.includes("31")} readOnly />
                          <S.TeethItemSpan>47</S.TeethItemSpan>
                        </S.TeethItemLabel>
                      </S.TeethItem>
                    )}
                    {filteredConfig
                      .slice(sliceNumber * 2, sliceNumber * 3)
                      .map(
                        ([key, value], index) =>
                          value && (
                            <TeethSummaryCheckItem
                              key={key}
                              value={getTeethKeyFromLabel(teeth_label[sliceNumber * 2 + index])}
                              itemName={`${code}_2_01`}
                              teethLabel={teeth_label[sliceNumber * 2 + index]}
                              TeethCheckbox
                              studyInfo={studyInfo}
                            />
                          )
                      )}
                  </div>
                </td>

                <td>
                  {studyInfo.study.age === "child" && (
                    <div>
                      {filteredConfig
                        .slice(sliceNumberBaby * 3, sliceNumberBaby * 4)
                        .map(
                          ([key, value], index) =>
                            value && (
                              <TeethSummaryCheckItem
                                key={key}
                                value={babyTeethValues[sliceNumberBaby * 3 + index]}
                                itemName={`${code}_2_02`}
                                TeethRadio
                                teethLabel={babyTeeth_label[sliceNumberBaby * 3 + index]}
                                studyInfo={studyInfo}
                              />
                            )
                        )}
                    </div>
                  )}
                  <div>
                    {filteredConfig
                      .slice(sliceNumber * 3, sliceNumber * 4)
                      .map(
                        ([key, value], index) =>
                          value && (
                            <TeethSummaryCheckItem
                              key={key}
                              value={getTeethKeyFromLabel(teeth_label[sliceNumber * 3 + index])}
                              itemName={`${code}_2_01`}
                              TeethCheckbox
                              teethLabel={teeth_label[sliceNumber * 3 + index]}
                              studyInfo={studyInfo}
                            />
                          )
                      )}
                    {(studyInfo.SI01 === "1" || studyInfo.SI01 === "3") && label === "permanent_predict" && (
                      <S.TeethItem>
                        <S.TeethItemLabel>
                          <S.TeethItemInput $TeethRadio type="checkbox" onClick={onCheckReacOnly} checked={studyInfo.SI14_01 === "2" && studyInfo.SI14_02_2_04?.includes("18")} readOnly />
                          <S.TeethItemSpan>37</S.TeethItemSpan>
                        </S.TeethItemLabel>
                      </S.TeethItem>
                    )}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </S.TeethTable>
        <S.TeethDirection>L</S.TeethDirection>
      </S.TeethContainer>
    </>
  );
}

export default TeethSummaryBox;

export const TeethSummaryCheckItem = ({ teethLabel, TeethCheckbox, TeethRadio, itemName, value, studyInfo }) => {
  /**readOnly 속성 걸기 */
  const onCheckReacOnly = (e) => {
    e.preventDefault();
    return false;
  };

  /**배열로 변경해서 요소 검사 */
  const teethArray = studyInfo[itemName]?.split(",");

  return (
    <S.TeethItem>
      <S.TeethItemLabel>
        <S.TeethItemInput $TeethCheckbox={TeethCheckbox} $TeethRadio={TeethRadio} type="checkbox" onClick={onCheckReacOnly} checked={teethArray?.includes(value)} readOnly />
        <S.TeethItemSpan>{teethLabel}</S.TeethItemSpan>
      </S.TeethItemLabel>
    </S.TeethItem>
  );
};
