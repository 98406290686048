import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ImageViewer from "react-simple-image-viewer";
import { DropImageBox } from "../../elements/Drop/DropImageBox";
import * as S from "../../styles/Common";
import { IK } from "../../utils/i18n/keyStore";
import { STUDY_EXTRO_IMAGES, STUDY_INTRO_IMAGES, STUDY_RADIOGRAPHS } from "../../utils/studyStatus";

const PatientFileInfo = ({ file }) => {
  const { t } = useTranslation(["translation"]);

  /**확대 이미지 선택 */
  const [imageViewer, setImageViewer] = useState({ imgSrc: [], isView: false });

  /**이미지 뷰어 열기 */
  const handleImageViewer = (src) => {
    setImageViewer({ imgSrc: [src], isView: true });
  };

  return (
    <>
      <DropImageBox text={t(IK.extraoral)} imagesArr={STUDY_EXTRO_IMAGES} file={file} handleImageViewer={handleImageViewer} />
      <DropImageBox text={t(IK.intraoral)} imagesArr={STUDY_INTRO_IMAGES} file={file} handleImageViewer={handleImageViewer} />
      <DropImageBox text={t(IK.radiograph)} imagesArr={STUDY_RADIOGRAPHS} file={file} isRadiation handleImageViewer={handleImageViewer} />

      {imageViewer.isView && (
        <S.ImageViewerBox>
          <ImageViewer
            src={imageViewer?.imgSrc}
            currentIndex={0}
            onClose={() => setImageViewer({ isView: false })}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.6)",
            }}
            closeOnClickOutside={true}
          />
        </S.ImageViewerBox>
      )}
    </>
  );
};

export default PatientFileInfo;
