import dayjs from "dayjs";
import ApprovedSeraview from "../components/study/studyStatus/ApprovedSeraview";
import ConfirmSeraview from "../components/study/studyStatus/ConfirmSeraview";
import DevelopeStudyPlan from "../components/study/studyStatus/DevelopeStudyPlan";
import EndOfTreatment from "../components/study/studyStatus/EndOfTreatment";
import ProgressAligner from "../components/study/studyStatus/ProgressAligner";
import ReceiptStudy from "../components/study/studyStatus/ReceiptStudy";
import RejectStudy from "../components/study/studyStatus/RejectStudy";
import ShippedDevice from "../components/study/studyStatus/ShippedDevice";
import SubmitStudy from "../components/study/studyStatus/SubmitStudy";
import TransitDevice from "../components/study/studyStatus/TransitDevice";
import WritingStudy from "../components/study/studyStatus/WritingStudy";
import teethLabelJson from "./TeethLabel.json";
import { IK } from "./i18n/keyStore";

/**처방전 상태 라벨 */
export function studyStatusLabel(key, t) {
  const data = {
    "01": t(IK.writing_study), //처방전 작성중
    11: t(IK.reject_study), //처방전 반려
    13: t(IK.submit_study), //처방전 제출
    14: t(IK.receipt_study), //처방전 접수
    15: t(IK.develope_study_plan), //세라뷰 디자인중
    16: t(IK.confirm_seraview), //세라뷰 검토요청
    17: t(IK.approved_seraview), //세라뷰 승인
    18: t(IK.progress_aligner), //장치 제작중
    19: t(IK.transit_device), //장치 배송중
    20: t(IK.shipped_device), //장치 배송완료
    22: t(IK.end_of_treatment), //치료 종료
  };

  return data[key] ? data[key] : key ? data["01"] : "";
}

/**처방전 내역 라벨 */
export function studyStatusComponents(props, key) {
  const data = {
    "01": <WritingStudy {...props} />,
    11: <RejectStudy {...props} />,
    13: <SubmitStudy {...props} />,
    14: <ReceiptStudy {...props} />,
    15: <DevelopeStudyPlan {...props} />,
    16: <ConfirmSeraview {...props} />,
    17: <ApprovedSeraview {...props} />,
    18: <ProgressAligner {...props} />,
    19: <TransitDevice {...props} />,
    20: <ShippedDevice {...props} />,
    22: <EndOfTreatment {...props} />,
  };
  return data[key] ? data[key] : key < 13 ? data["01"] : "";
}

/**처방전 Tr 상태,세부 항목 표기 */
export const studyStatusListLabel = (item, t) => {
  const detail = item?.detail;
  const status = studyStatusLabel(detail, t);

  if (detail === "11" && item?.rejectReason) {
    return `${status} [${t(REJECT_REASON_LIST[item.rejectReason])}]`;
  }

  if ((detail === "14" || detail === "18") && !!item.expectedDate) {
    const labelKey = detail === "14" ? IK.seraview_expected : IK.expacted_date_device;
    return `${status} (${t(labelKey)}: ${dayjs(item.expectedDate).format("MM/DD")})`;
  }

  if (detail === "16") {
    if (item.studyDesignTemporaryYn === "Y") {
      return t(IK.edit_seraview);
    }

    if (item?.reviseStudyDesignYn === "Y") {
      return t(IK.seraviw_3d_modify);
    }
  }

  return status;
};

/**어떤 처방전인지 반환*/
export const studyTypeLabel = (studyInfo) => {
  if (!!studyInfo?.additionalId) return "additional_device";
  if (!!studyInfo?.replacementId) return "alternative_correction_devices";
  return "main_study";
};

export const ARCH_LABEL = {
  1: "BOTH",
  2: "UPPER",
  3: "LOWER",
};

/**처방전 초기 스텝 */
export const SETTING_STUDY_STEP = ["SELECT_AGE", "SELECT_PACKAGE"];

/**처방전 패키지 스텝 */
export const STUDY_PACKAGES_STEP = {
  R: ["SI01", "SI02", "SI03", "SI10", "SI08", "SI09_01", "SI09_02", "SI13"],
  RP: ["SI01", "SI10", "SI13"],
  S10: ["SI01", "SI12", "SI02", "SI03", "SI04", "SI10", "SI08", "SI09_01", "SI09_02", "SI14", "SI13", "SI15"],
  S20: ["SI01", "SI12", "SI02", "SI03", "SI04", "SI05", "SI06", "SI07", "SI10", "SI08", "SI09_01", "SI09_02", "SI14", "SI13", "SI15"],
  SR: ["SI01", "SI12", "SI02", "SI03", "SI04", "SI05", "SI06", "SI07", "SI10", "SI08", "SI09_01", "SI09_02", "SI11", "SI14", "SI13", "SI17", "SI15"],
  SAP: ["SI01", "SI12", "SI02", "SI03", "SI04", "SI05", "SI06", "SI07", "SI10", "SI08", "SI09_01", "SI09_02", "SI11", "SI14", "SI13", "SI17", "SI15"],
  Si: ["SI01", "SI12", "SI02", "SI03", "SI04", "SI13"],
  SiAP: ["SI01", "SI12", "SI02", "SI03", "SI16", "SI04", "SI13"],
};

/**처방전 파일 스텝 + 요약, 확인 */
export const STUDY_FILE_STEP = ["EXTRAORAL", "INTRAORAL", "RADIOGRAPH", "SCAN_FILE", "SUMMARY", "CONFIRM"];

/**처방전 패키지 기준으로 스텝을 선택
 * @param {string} packages - 패키지 코드
 * @param {string} countryCode - 국가 코드
 * @returns {array} 선택된 스텝
 */
export const selectPackageStep = (packages, countryCode, SI01) => {
  const packageStep = STUDY_PACKAGES_STEP[packages];
  const step = [...SETTING_STUDY_STEP, ...packageStep, ...STUDY_FILE_STEP];

  const skipCountryItems = ["SI15", "SI17"];

  if (countryCode !== "01") {
    //해외 처방전은 애브리텐(SI15), 장치배송의 기본 단위(SI17) 제거
    return step.filter((e) => !skipCountryItems.includes(e));
  }

  if (SI01) {
    //악궁값 받은경우 교합이개 필터
    return removeBiteRamp(step, SI01);
  }

  return step;
};

/**처방전 SI01에서 하악선택시 BiteRamp(SI10) 제거 */
export const removeBiteRamp = (step, SI01) => {
  if (SI01 === "3") {
    return step.filter((e) => e !== "SI10");
  }
  return step;
};

/**처방전 요약화면 정보 라벨링 */
export function getSummaryLabel(studyInfo, key, t) {
  const valueLabel = {
    SI01: {
      1: t(IK.bimaxillay),
      2: t(IK.maxilla),
      3: t(IK.mandible),
    },
    SI01_2_01: {
      1: t(IK.dental_arch_treated_item2_1),
      2: t(IK.dental_arch_treated_item2_2),
      3: t(IK.dental_arch_treated_item2_3),
    },
    SI01_3_01: {
      1: t(IK.dental_arch_treated_item3_1),
      2: t(IK.dental_arch_treated_item3_2),
      3: t(IK.dental_arch_treated_item3_3),
    },
    SI02: {
      1: t(IK.teeth_limited_mobility_item1),
      2: t(IK.teeth_limited_mobility_item2),
    },
    SI03: {
      1: t(IK.attachment_item1),
      2: t(IK.attachment_item2),
    },
    SI04_01: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI04_02: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI05_01: {
      1: t(IK.ap_item1_1),
      2: t(IK.ap_item1_2),
      3: t(IK.ap_item1_3),
    },
    SI05_02: {
      1: `${t(IK.ap_right)} : ${t(IK.ap_item3_title)}`,
      2: `${t(IK.ap_right)} : ${t(IK.ap_item4_title)}`,
    },
    SI05_03: {
      1: `${t(IK.ap_left)} : ${t(IK.ap_item3_title)}`,
      2: `${t(IK.ap_left)} : ${t(IK.ap_item4_title)}`,
    },
    SI05_04: {
      1: t(IK.ap_item5_1),
      2: t(IK.ap_item5_2),
      3: t(IK.ap_item5_3),
      4: t(IK.ap_item5_4),
      5: t(IK.ap_item5_5),
    },
    SI06: {
      1: t(IK.overjet_item1),
      2: t(IK.deepbite_item2_2),
      3: t(IK.overjet_item3),
    },
    SI07_01: {
      1: t(IK.deepbite_item1_1),
      2: t(IK.deepbite_item1_2),
      3: t(IK.deepbite_item1_3),
    },
    SI07_02: {
      1: t(IK.deepbite_item2_1),
      2: t(IK.deepbite_item2_2),
      3: t(IK.deepbite_item2_3),
    },
    SI07_03: {
      1: t(IK.deepbite_item3_1),
      2: t(IK.deepbite_item3_2),
      3: t(IK.deepbite_item3_3),
      4: t(IK.deepbite_item3_4),
      5: t(IK.other),
      6: "",
    },
    SI07_04: {
      1: t(IK.deepbite_item4_1),
      2: t(IK.deepbite_item4_2),
      3: t(IK.deepbite_item4_3),
      4: t(IK.deepbite_item4_4),
      5: t(IK.other),
      6: "",
    },
    SI08: {
      1: t(IK.midline_item1),
      2: t(IK.midline_item2),
      3: t(IK.midline_item3),
      4: t(IK.midline_item4),
    },
    SI08_3_01: {
      1: t(IK.midline_item1),
      2: t(IK.midline_item2),
      3: t(IK.midline_item3),
      4: t(IK.midline_item4),
    },
    SI08_3_02: {
      1: t(IK.midline_item3_1),
      2: t(IK.midline_item3_2),
      3: t(IK.midline_item3_3),
    },
    SI09_01: {
      1: t(IK.spacing_item1),
      2: t(IK.spacing_item2),
    },
    SI09_02_01_01: {
      1: t(IK.increase_incline),
      2: t(IK.maintain_incline),
      3: t(IK.decrease_incline),
    },
    SI09_02_01_02: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI09_02_01_03: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI09_02_01_04: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI09_02_02_01: {
      1: t(IK.increase_incline),
      2: t(IK.maintain_incline),
      3: t(IK.decrease_incline),
    },
    SI09_02_02_02: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI09_02_02_03: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI09_02_02_04: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI10: {
      1: t(IK.grant_if_necessary),
      2: t(IK.biteramp_item2),
      3: t(IK.not_used),
    },
    SI10_2_01: {
      1: t(IK.incisor),
      2: t(IK.canine_teeth),
    },
    SI10_2_01_1_01: {
      1: t(IK.central_incisor),
      2: t(IK.lateral_incisor),
    },
    SI11: {
      1: t(IK.crossbite_item1),
      2: t(IK.crossbite_item2),
    },
    SI12: {
      1: t(IK.extraction_item1),
      2: t(IK.have_teeth_to_be_extracted),
    },
    SI14: {
      1: t(IK.not_applicable),
    },
    SI14_01: {
      1: t(IK.not_used),
      2: t(IK.prmanent_tooth_item1_2),
    },
    SI14_02: {
      1: t(IK.not_used),
      2: t(IK.prmanent_tooth_item1_2),
    },
    SI15: {
      1: t(IK.yes),
      2: t(IK.no),
    },
    SI16: {
      1: t(IK.ap_attachment_text_1),
      2: t(IK.ap_attachment_text_2),
      3: t(IK.ap_attachment_text_3),
    },
    SI17: {
      1: t(IK.device_delivery_unit_item1_1),
      2: t(IK.device_delivery_unit_item1_2),
    },
    SIPlasterModel: {
      "Direct shipping": t(IK.address_delivery_check_title2),
      "Delivery pickup": t(IK.address_delivery_check_title1),
    },
  };
  const value = studyInfo[key] || [];
  if (value.includes(",")) {
    // 쉼표가 포함된 경우 여러 개의 레이블을 가져와 합쳐서 반환
    const splitArr = value.split(",");
    return splitArr.map((item) => valueLabel[key][item]).join(", ");
  }
  return valueLabel[key][value] || "";
}
/**처방전 요약화면 치아 라벨링 */
export function getTeethLabel(data) {
  const splitArr = data ? data.split(",") : [];
  const result = splitArr.map((item) => teethLabelJson[item]);
  return result.join(", ");
}

/**임상 상태 표기하기 */
export const CLINIC_CONDITION = {
  crowding: IK.crowding,
  spacing: IK.Interdental_space,
  class1: "Class I",
  class2: "Class II div 1",
  class3: "Class II div 2",
  class4: "Class III",
  asymmetric: IK.asymmetry,
  openbiteVerticality: IK.open_bite_vertical,
  openbiteOverjet: IK.open_bite_horizontal,
  deepBite: IK.deepbite,
  abteriorCrossbite: IK.anterior_crossbite,
  posteriorCrossbite: IK.posterior_crossbite,
  narrowArch: IK.narrow_dental_arch,
  flaredTeeth: IK.prolapse_of_anterior_teeth,
  unevenSmile: IK.smile_line_needs_improvement,
  occlusalPlaneRight: IK.Inclined_occlusal_plane_right,
  occlusalPlaneLeft: IK.Inclined_occlusal_plane_left,
  occlusalPlane: IK.occlusal_plane,
  misshapenTeeth: IK.abnormally_shaped_teeth,
  missing: IK.missing,
  other: IK.other,
  gingivalRecession: IK.gingival_recession,
  scissorsBite: IK.scissors_bite,
};

/**임상상태가 배열인 경우 */
export function getConditionItem(data, t) {
  return data
    .filter((item) => item.itemKey !== "other")
    .map((item) => (item.itemKey === "otherText" ? `${item.itemValue}(${t(CLINIC_CONDITION["other"])})` : t(CLINIC_CONDITION[item.itemKey])))
    .join(", ");
}

/**처방전 이미지 관련 리스트 */
export const STUDY_INTRO_IMAGES = ["occlusal_upper", "intraoral_front", "occlusal_lower", "buccal_right", "overjet", "buccal_left"];
export const STUDY_EXTRO_IMAGES = ["extraoral_front", "front_rest", "front_smile", "side45", "side90", "side90_smile"];
export const STUDY_RADIOGRAPHS = ["panorama", "cephalo"];

/**추가교정장치 비교중첩 데이터 */
export const ADDITIONAL_OVERLAP_DATA = [
  {
    classImg: "occlusal_upper",
    fileImg: "ovlp_occlusal_up",
  },
  {
    classImg: "overjet_front_top",
    fileImg: "ovlp_intraoral_up",
  },
  {
    classImg: "upper_buccal_right",
    fileImg: "ovlp_intraoral_lwr",
  },
  {
    classImg: "upper_buccal_left",
    fileImg: "ovlp_occlusal_lwr",
  },
  {
    classImg: "occlusal_lower",
    fileImg: "ovlp_buccal_r_up",
  },
  {
    classImg: "overjet_front_bottom",
    fileImg: "ovlp_buccal_r_lwr",
  },
  {
    classImg: "lower_buccal_right",
    fileImg: "ovlp_buccal_l_up",
  },
  {
    classImg: "lower_buccal_left",
    fileImg: "ovlp_buccal_l_lwr",
  },
];

/**세라핀 옵션명 통일 */
export const SERAPIN_OPTION = {
  R: "R-active",
  RP: "R-passive",
  S10: "10",
  S20: "20",
  SR: "Regular",
  SAP: "Regular AP",
  Si: "i",
  SiAP: "iAP",
};

/**치아 선택 배열
 * 1부터 32까지 치아 번호
 */
export const TEETH_SELECT_ARRAY = {
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true,
  7: true,
  8: true,
  9: true,
  10: true,
  11: true,
  12: true,
  13: true,
  14: true,
  15: true,
  16: true,
  17: true,
  18: true,
  19: true,
  20: true,
  21: true,
  22: true,
  23: true,
  24: true,
  25: true,
  26: true,
  27: true,
  28: true,
  29: true,
  30: true,
  31: true,
  32: true,
};

/**영구치 치아박스 */
export const PERMANENT_TOOTH_ARRAY = {
  4: true,
  5: true,
  6: true,
  11: true,
  12: true,
  13: true,
  29: true,
  28: true,
  27: true,
  22: true,
  21: true,
  20: true,
};

/**반려사유 */
export const REJECT_REASON_LIST = {
  REJECT_SCAN: IK.reject_scan,
  REJECT_MODEL: IK.reject_model,
  REJECT_OCCLUSION: IK.reject_occlusion,
  NO_TREATMENT_PLAN_REMAINING: IK.no_treatment_plan_remaining,
  REJECT_ETC: IK.other,
};

/**추가 처방전 석고모델 보내기 상세 표기 */
/**
 * plasterModelSend 함수는 주어진 텍스트와 키에 따라 모델 텍스트를 반환합니다.
 * @param {function} t - 다국어 번역 함수
 * @param {string} key - 석고모델 하위 옵션 키
 * @param {string} scanEmpty - 스캔이 비어있는지 여부를 나타내는 값(추가 처방전만 사용, AI40 === "X")
 * @returns {string} - 주어진 키에 해당하는 모델 텍스트
 */
export const plasterModelSend = (t, key) => {
  if (key === "None") {
    return t(IK.none);
  }

  const modelText = {
    "Direct shipping": t(IK.address_delivery_check_title2),
    "Delivery pickup": t(IK.address_delivery_check_title1),
  };

  return `${t(IK.send_model)}${modelText[key] ? `: ${modelText[key]}` : ""}`;
};

/**처방전 패키지 기준으로 스텝을 선택
 * @param {string} steps - 패키지스텝
 * @param {string} countryCode - 국가 코드
 * @returns {array} 선택된 스텝
 */
export const selectAdditionalPackageStep = (steps, countryCode) => {
  const skipCountryItems = ["AI08"];

  if (countryCode !== "01") {
    //해외 처방전은 장치배송의 기본 단위(SI17) 제거
    return steps.filter((e) => !skipCountryItems.includes(e));
  }

  return steps;
};

/**처방전 패키지 스텝
 * RP는 추가교정장치 없음
 * NOT_EDIT는 UPDATE_ADDITIONAL에서 처방전 수정 없음 선택하는 경우
 */
export const ADDITIONAL_STUDY_PACKAGES_STEP = {
  NOT_EDIT: ["UPDATE_ADDITIONAL", "AI06", "SCAN_FILE", "SUMMARY"],
  R: ["AI02", "AI04", "AI05", "AI06", "EXTRAORAL", "INTRAORAL", "RADIOGRAPH", "SCAN_FILE", "SUMMARY"],
  RP: [], //추가교정장치 없음
  S10: ["AI02", "AI04", "AI05", "AI06", "EXTRAORAL", "INTRAORAL", "RADIOGRAPH", "SCAN_FILE", "SUMMARY"],
  S20: ["AI02", "AI04", "AI05", "AI06", "EXTRAORAL", "INTRAORAL", "RADIOGRAPH", "SCAN_FILE", "SUMMARY"],
  SR: ["UPDATE_ADDITIONAL", "AI02", "AI04", "AI05", "AI06", "AI08", "EXTRAORAL", "INTRAORAL", "RADIOGRAPH", "SCAN_FILE", "SUMMARY"],
  SAP: ["UPDATE_ADDITIONAL", "AI02", "AI04", "AI05", "AI06", "AI08", "EXTRAORAL", "INTRAORAL", "RADIOGRAPH", "SCAN_FILE", "SUMMARY"],
  Si: ["UPDATE_ADDITIONAL", "AI02", "AI04", "AI05", "AI06", "EXTRAORAL", "INTRAORAL", "RADIOGRAPH", "SCAN_FILE", "SUMMARY"],
  SiAP: ["UPDATE_ADDITIONAL", "AI02", "AI04", "AI07", "AI05", "AI06", "EXTRAORAL", "INTRAORAL", "RADIOGRAPH", "SCAN_FILE", "SUMMARY"],
};
