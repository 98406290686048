import { yupResolver } from "@hookform/resolvers/yup";
import Notiflix, { Confirm } from "notiflix";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { checkEmail } from "../apis/accountApi";
import { useDeleteDoctor, useEditDoctorCoin, useEditDoctorInfo, useGetDoctorAddress, useGetDoctorCoin, useGetDoctorInfo } from "../apis/doctorApi";
import { downloadFile } from "../apis/downloadApi";
import AddressEditModal from "../components/doctor/AddressEditModal";
import CoinHistoryModal from "../components/doctor/CoinHistoryModal";
import DoctorViewSkeleton from "../components/doctor/DoctorViewSkeleton";
import { CardTail, CardTitle } from "../components/layout/CardLayout/CardLayout";
import * as S from "../components/layout/CardLayout/style/CardLayout.style";
import * as S3 from "../elements/Button/style/ButtonLayout.style";
import { FormControlsBox } from "../elements/Form/FormLayout";
import * as S2 from "../elements/Form/style/Form.style";
import { ErrorMessage, NotiMessage, SuccessMessage } from "../elements/Message/MessageText";
import * as S4 from "../elements/SelectionControls/style/SelectionControls.style";
import { usePermissionCheck } from "../hooks/usePermissionCheck";
import * as S5 from "../styles/Common";
import { doctorAuthGrade, doctorGrade } from "../utils/doctorUtils";
import { enterKeyDown } from "../utils/enterKeydown";
import { IK } from "../utils/i18n/keyStore";
import { countryLabel } from "../utils/searchLabel";

const DoctorView = () => {
  const { doctorId } = useParams();
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const location = useLocation();

  /**의사 상세 조회 */
  const { data, isLoading } = useGetDoctorInfo(doctorId);
  const doctorInfo = data?.data;

  /**의사 코인 조회 */
  const { data: coinData, isCoinLoading } = useGetDoctorCoin(doctorId);

  /**권한 조회 */
  const doctorAuth = usePermissionCheck("doctorList");

  const [emailCheckFlag, setEmailCheckFlag] = useState(false);

  /**유효성 검사 */
  const formSchema = Yup.object({
    koreaName: Yup.string().when("countryCode", {
      is: (val) => val === "01",
      then: (val) =>
        Yup.string()
          .matches(/^[가-힣]*$/, t(IK.only_korean))
          .required(t(IK.korea_name_required))
          .max(10, t(IK.name_length_limit_10)),
    }),
    name: Yup.string()
      .required(t(IK.english_name_required))
      .matches(/^[a-zA-Z\s]*$/, t(IK.only_engilsh))
      .test("no-leading-trailing-space", t(IK.between_space), (value) => {
        return value === value.trim();
      }),
    loginPw: Yup.string()
      .nullable()
      .test("loginPw", t(IK.password_validate), (value) => {
        if (!value) {
          return true; // 값이 없을 때는 검사를 통과
        }
        return /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?!\s).{8,16}$/.test(value) && value.length <= 16;
      }),

    email: Yup.string()
      .required(t(IK.email_required))
      .email(t(IK.email_valid))
      .test("email", t(IK.email_valid), (value) => {
        if (value) {
          // 영어 대소문자, 숫자, 하이픈(-)만 허용
          const regex = /^[A-Za-z0-9_-]+@[A-Za-z0-9-]+\.[A-Za-z]{2,}$/;
          return regex.test(value);
        }
        return true;
      }),
    emailCheck: Yup.bool().isTrue().oneOf([true], t(IK.email_duplicate_check_required)),
    tel: Yup.string().required(t(IK.clinic_number_required)),
    phoneNumber: Yup.string().required(t(IK.number_required)),
    grade: Yup.string().required(t(IK.grade_required)),
    countryCode: Yup.string().required(t(IK.country_required)),
    status: Yup.string().required(t(IK.grade_required)),
    doctorType: Yup.string().required(t(IK.doctor_Type_required)),
    doctorClassification: Yup.string().required(t(IK.doctor_classification_required)),
    partnerName: Yup.string().when("countryCode", {
      is: (val) => val === "01",
      then: (val) => Yup.string().required(t(IK.partnerName_required)),
    }),
  });

  /**중복검사 초기값 설정 */
  const defaultValues = {
    emailCheck: true,
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  /**주소지 조회 */
  const { data: addressData } = useGetDoctorAddress(doctorId);
  const addressList = addressData?.data || [];
  /**주소지 선택 */
  const [selecteShip, setSelecteShip] = useState({});
  const [selecteBill, setSelecteBill] = useState({});

  /**영상 전제 시청 선택 */
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked ? "Y" : "N";
    const videoFields = ["video1Yn", "video2Yn", "video3Yn", "video4Yn"];
    videoFields.forEach((field) => {
      setValue(field, isChecked);
    });
  };

  const watchedVideos = watch(["video1Yn", "video2Yn", "video3Yn", "video4Yn"]);
  const countryCode = watch("countryCode") || doctorInfo?.countryCode;

  const handleSelect = (event, field) => {
    const isChecked = event.target.checked ? "Y" : "N";
    setValue(field, isChecked);
  };

  /**의사 프로필 수정하기 */
  const doctorEditMutation = useEditDoctorInfo();

  /**의사 프로필 삭제 */
  const doctorDeleteMutation = useDeleteDoctor();

  /**의사상세 수정 */
  const onEditDoctor = (data) => {
    const { staffContact1, staffContact2, ...rest } = data;

    const staffContact = [];

    //스태프 contact 추가
    const addStaffContact = (contact, contactId) => {
      if (!!contact || !!contactId) {
        staffContact.push({ contact, ...(contactId && { contactId }) });
      }
    };

    addStaffContact(staffContact1, doctorInfo?.staffContactList[0]?.contactId);
    addStaffContact(staffContact2, doctorInfo?.staffContactList[1]?.contactId);

    const filteredObj = Object.fromEntries(Object.entries({ ...rest, staffContact }).filter(([key, value]) => !!value));

    doctorEditMutation.mutate({ id: doctorId, data: filteredObj });
  };

  /**의사 데이터 삭제 */
  const onDeleteDoctor = () => {
    Confirm.show(
      t(IK.warning_delete_1),
      t(IK.warning_delete_2),
      t(IK.confirm),
      t(IK.cancel),
      () => {
        doctorDeleteMutation.mutate(doctorId);
      },
      () => {}
    );
  };

  /**이메일 중복체크 */
  const dupulicateEmail = () => {
    const email = getValues("email");

    if (emailCheckFlag) return;

    if (!(email && /^[a-zA-Z0-9_-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/.test(email)))
      return setError("email", {
        type: "manual",
        message: t(IK.email_valid),
      });

    if (email) {
      checkEmail({ email })
        .then((response) => {
          setEmailCheckFlag(true);
          setValue("emailCheck", true);
        })
        .catch((err) => {
          if (err?.response?.status === 410)
            return setError("emailCheck", {
              type: "manual",
              message: t(IK.duplicate_email),
            });

          if (err?.response?.status === 411)
            return setError("email", {
              type: "manual",
              message: t(IK.email_valid),
            });

          return Notiflix.Report.warning(t(IK.unknown_err_1), t(IK.unknown_err_2), t(IK.confirm), () => {});
        });
    }
  };

  /**중복 검사 */
  const handleKeyDown = (event) => {
    setValue("emailCheck", false);
    setEmailCheckFlag(false);
  };

  /**의사 상태코드 */
  const doctorStatus = {
    APPROVED: t(IK.status_apporve),
    REJECT: t(IK.status_reject),
    UNAPPROVED: t(IK.status_pending),
  };

  /**의사 주소 수정 모달  */
  const [addressModal, setAddressModal] = useState({
    isOpen: false,
    type: "",
  });

  /**주소 모달 열기 */
  const handleOpenAddressModal = (type) =>
    setAddressModal({
      isOpen: true,
      type,
    });

  /**주소 모달 닫기 */
  const handleCloseAddressModal = () =>
    setAddressModal({
      isOpen: false,
      type: "",
    });

  /**코인사용 내역 모달 */
  const [coinModal, setCoinModal] = useState(false);
  const openCoinModal = () => {
    setCoinModal(true);
  };

  /**코인 충전/차감 관리 */
  const [isCoinCharge, setIsCoinCharge] = useState({
    isEdit: false,
    memo: "",
    amount: "",
  });

  /**코인 충전/차감 메모 */
  const handleCoinMemo = (e) => {
    setIsCoinCharge((prev) => ({ ...prev, memo: e.target.value }));
  };

  /**코인 충전차감 갯수 */
  const handleCoinAmount = (e) => {
    setIsCoinCharge((prev) => ({ ...prev, amount: e.target.value }));
  };

  const doctorCoinChargeMutation = useEditDoctorCoin();
  /**코인 충전차감 요청 */
  const handleCoinCharge = (type) => {
    const { amount, memo } = isCoinCharge;

    if (amount < 1) return Notiflix.Notify.failure(t(IK.coin_amount_required));

    Notiflix.Confirm.show(
      `${t(IK.coin)} ${type === "CHARGE" ? t(IK.pay_charging) : t(IK.pay_using)}`,
      type === "CHARGE" ? t(IK.coin_type_charge) : t(IK.coin_type_deduction),
      type === "CHARGE" ? t(IK.pay_charging) : t(IK.pay_using),
      t(IK.cancel),
      () => {
        doctorCoinChargeMutation.mutate(
          { doctorId, data: { amount, memo, type } },
          {
            onSuccess: () => {
              setIsCoinCharge({ memo: "", amount: "", isEdit: false });
            },
          }
        );
      }
    );
  };

  useEffect(() => {
    if (!isLoading) {
      setValue("status", doctorInfo.status);
      setSelecteShip(doctorInfo?.shipAddress);
      setSelecteBill(doctorInfo?.billAddress);
    }
  }, [isLoading, doctorInfo, setValue]);

  return (
    <>
      <S.CardRow>
        <S.CardCol>
          {isLoading || isCoinLoading ? (
            <DoctorViewSkeleton />
          ) : (
            <S.CardInner>
              <CardTitle title={`${doctorInfo.name}(${doctorInfo.number})`} txt />
              <form onSubmit={handleSubmit(onEditDoctor)} onKeyDown={enterKeyDown}>
                <S2.FormCardList $bgwhite>
                  <li>
                    <S2.FormControlsContainer>
                      {/* 국가 */}
                      <FormControlsBox required regular title={t(IK.country)}>
                        <Controller
                          name="countryCode"
                          control={control}
                          defaultValue={doctorInfo.countryCode}
                          render={({ field }) => (
                            <S2.FormControls {...register("countryCode")} as="select" $select>
                              {countryLabel.map((option) => {
                                //의사는 전체 국가 선택 못함
                                if (option.value === "00") return null;
                                return (
                                  <option key={option.value} value={option.value}>
                                    {t(option.label)}
                                  </option>
                                );
                              })}
                            </S2.FormControls>
                          )}
                        />
                        <ErrorMessage errors={errors} name="countryCode" />
                      </FormControlsBox>

                      {/* 담당자 */}
                      {countryCode === "01" && (
                        <FormControlsBox required regular title={t(IK.recommender)}>
                          <S2.FormControls defaultValue={doctorInfo?.partnerName} {...register("partnerName")} as="select" $select>
                            <option value="">{t(IK.select)}</option>
                            <option value="티에네스">{t(IK.doctor_tns)}</option>
                            <option value="덴티스">{t(IK.doctor_dentis)}</option>
                          </S2.FormControls>
                          <ErrorMessage errors={errors} name="partnerName" />
                        </FormControlsBox>
                      )}

                      {/* 가입유형 */}
                      <FormControlsBox required regular title={t(IK.doctor_type)}>
                        <S2.FormControls defaultValue={doctorInfo?.doctorType} {...register("doctorType")} as="select" $select>
                          <option value="">{t(IK.select)}</option>
                          <option value="TNS">{t(IK.doctor_tns)}</option>
                          <option value="DENTIS">{t(IK.doctor_dentis)}</option>
                        </S2.FormControls>
                        <ErrorMessage errors={errors} name="doctorType" />
                      </FormControlsBox>

                      {/* 의사유형 */}
                      <FormControlsBox required regular title={t(IK.doctor_type_classification)}>
                        <S2.FormControls defaultValue={doctorInfo?.doctorClassification} {...register("doctorClassification")} as="select" $select>
                          <option value="">{t(IK.select)}</option>
                          <option value="ORTHODONTIST">{t(IK.doctor_orthodontists)}</option>
                          <option value="PEDIATRIC">{t(IK.doctor_pediatrics)}</option>
                          <option value="UNIVERSITY">{t(IK.doctor_university)}</option>
                          <option value="ETC">{t(IK.other)}</option>
                          <option value="INTERNATIONAL">{t(IK.doctor_International)}</option>
                        </S2.FormControls>
                        <ErrorMessage errors={errors} name="doctorClassification" />
                      </FormControlsBox>

                      {/* 면허번호 */}
                      <FormControlsBox required regular title={t(IK.doctor_license)}>
                        {doctorInfo?.license}
                      </FormControlsBox>

                      {/* 이름(한글) */}
                      {countryCode === "01" && (
                        <FormControlsBox required regular title={t(IK.korea_name)}>
                          <S2.FormControls placeholder={t(IK.korea_name_required)} defaultValue={doctorInfo?.koreaName} {...register("koreaName")} maxLength="10" />
                          <ErrorMessage errors={errors} name="koreaName" />
                        </FormControlsBox>
                      )}

                      {/* 이름(영문) */}
                      <FormControlsBox required regular title={t(IK.english_name)}>
                        <S2.FormControls placeholder={t(IK.english_name_required)} defaultValue={doctorInfo.name} {...register("name")} maxLength="20" />
                        <ErrorMessage errors={errors} name="name" />
                      </FormControlsBox>

                      {/* 아이디 */}
                      <FormControlsBox required regular title={t(IK.id)}>
                        {doctorInfo.loginId}
                      </FormControlsBox>

                      {/* 비밀번호 */}
                      <FormControlsBox required regular title={t(IK.password)}>
                        <S2.FormControlsFlex>
                          <S2.FormControls placeholder={t(IK.password)} type="password" {...register("loginPw")} maxLength="16" autoComplete="new-password" />
                        </S2.FormControlsFlex>
                        <ErrorMessage errors={errors} name="loginPw" />
                      </FormControlsBox>

                      {/* 연락처(휴대전화) */}
                      <FormControlsBox required regular title={t(IK.contact_phone)}>
                        <S2.FormControls placeholder={t(IK.contact_phone)} defaultValue={doctorInfo.phoneNumber} {...register("phoneNumber")} />
                        <ErrorMessage errors={errors} name="phoneNumber" />
                      </FormControlsBox>

                      {/* 이메일 */}
                      <FormControlsBox required regular title={t(IK.email)}>
                        <S2.FormControlsFlex>
                          <S2.FormControls placeholder={t(IK.email)} defaultValue={doctorInfo.email} {...register("email")} onKeyDown={handleKeyDown} />
                          <S2.FormControlsBtn type="button" onClick={dupulicateEmail}>
                            {t(IK.duplicate_btn)}
                          </S2.FormControlsBtn>
                        </S2.FormControlsFlex>
                        <ErrorMessage errors={errors} name="email" />
                        {emailCheckFlag ? <SuccessMessage text={t(IK.available_email_message)} /> : <ErrorMessage errors={errors} name="emailCheck" />}
                      </FormControlsBox>
                    </S2.FormControlsContainer>

                    <S5.ContentLine>
                      <S2.FormControlsContainer>
                        {/* Staff 아이디 */}
                        <FormControlsBox regular title={t(IK.staff_id)}>
                          {doctorInfo?.staffLoginId ? <>{doctorInfo?.staffLoginId}</> : <NotiMessage text={t(IK.staff_id_empty)} mt0 />}
                        </FormControlsBox>

                        {/* Staff 연락처1 */}
                        <FormControlsBox regular title={doctorInfo?.countryCode === "01" ? `${t(IK.staff_contact)}1` : `${t(IK.staff_email)}1`}>
                          <S2.FormControls defaultValue={doctorInfo?.staffContactList?.[0]?.contact} {...register("staffContact1")} />
                        </FormControlsBox>

                        {/* Staff 연락처2 */}
                        <FormControlsBox regular title={doctorInfo?.countryCode === "01" ? `${t(IK.staff_contact)}2` : `${t(IK.staff_email)}2`}>
                          <S2.FormControls defaultValue={doctorInfo?.staffContactList?.[1]?.contact} {...register("staffContact2")} />
                        </FormControlsBox>
                      </S2.FormControlsContainer>
                    </S5.ContentLine>
                  </li>

                  <li>
                    <S2.FormControlsContainer>
                      {/* 배송지 */}
                      <FormControlsBox regular title={t(IK.shipping_address)}>
                        <S2.FormControlsColumn>
                          <S4.SelectionItemList>
                            {addressList.map((item) => {
                              return (
                                <S4.SelectionItem key={"ship_" + item.addressId}>
                                  <S4.SelectionItemLabel>
                                    <S4.SelectionItemInput
                                      {...register("shipAddressId")}
                                      type="radio"
                                      onClick={() => {
                                        setSelecteShip(item);
                                      }}
                                      value={item.addressId}
                                      id={"ship_" + item.addressId}
                                      checked={selecteShip.addressId === item.addressId}
                                      $radio
                                    />
                                    <S4.SelectionItemSpan>{item.addressName}</S4.SelectionItemSpan>
                                  </S4.SelectionItemLabel>
                                </S4.SelectionItem>
                              );
                            })}
                          </S4.SelectionItemList>
                          <S2.FormControlsFlex>
                            <S2.FormControls value={selecteShip?.postalCode || ""} readOnly />
                            <S2.FormControlsBtn type="button" onClick={() => handleOpenAddressModal("ship")}>
                              {t(IK.modify)}
                            </S2.FormControlsBtn>
                          </S2.FormControlsFlex>
                          <S2.FormControls value={selecteShip?.address || ""} readOnly />
                          <S2.FormControls value={selecteShip?.addressDetail || ""} readOnly />
                        </S2.FormControlsColumn>
                      </FormControlsBox>

                      {/* 청구지 */}
                      <FormControlsBox regular title={t(IK.billing_address)}>
                        <S2.FormControlsColumn>
                          <S4.SelectionItemList>
                            {addressList.map((item) => {
                              return (
                                <S4.SelectionItem key={"bill_" + item.addressId}>
                                  <S4.SelectionItemLabel>
                                    <S4.SelectionItemInput
                                      {...register("billAddressId")}
                                      type="radio"
                                      onClick={() => {
                                        setSelecteBill(item);
                                      }}
                                      id={"bill_" + item.addressId}
                                      value={item.addressId}
                                      checked={selecteBill.addressId === item.addressId}
                                      $radio
                                    />
                                    <S4.SelectionItemSpan>{item.addressName}</S4.SelectionItemSpan>
                                  </S4.SelectionItemLabel>
                                </S4.SelectionItem>
                              );
                            })}
                          </S4.SelectionItemList>

                          <S2.FormControlsFlex>
                            <S2.FormControls value={selecteBill?.postalCode || ""} readOnly />
                            <S2.FormControlsBtn type="button" onClick={() => handleOpenAddressModal("bill")}>
                              {t(IK.modify)}
                            </S2.FormControlsBtn>
                          </S2.FormControlsFlex>
                          <S2.FormControls value={selecteBill?.address || ""} readOnly />
                          <S2.FormControls value={selecteBill?.addressDetail || ""} readOnly />
                        </S2.FormControlsColumn>
                      </FormControlsBox>

                      {/* 연락처(직장) */}
                      <FormControlsBox regular required title={t(IK.contact_company)}>
                        <S2.FormControls placeholder={t(IK.contact_company)} defaultValue={doctorInfo.tel} {...register("tel")} />
                        <ErrorMessage errors={errors} name="tel" />
                      </FormControlsBox>

                      {/* 치과로고 */}
                      <FormControlsBox regular title={t(IK.logo)}>
                        {doctorInfo.logoDistFileName ? (
                          <>
                            {/* <img src={doctorInfo?.logoDistFileName} /> */}
                            <S3.StyledSmallButton type="button" as="button" onClick={() => downloadFile(doctorInfo?.logoFileId, `${doctorInfo?.name}_${doctorInfo?.logoOrgFileName}`, t)}>
                              {doctorInfo?.logoOrgFileName}
                              <i className="ri-download-line"></i>
                            </S3.StyledSmallButton>
                          </>
                        ) : (
                          <NotiMessage text={t(IK.file_empty)} mt0 />
                        )}
                      </FormControlsBox>

                      {/* 사업자 등록증 */}
                      <FormControlsBox regular title={t(IK.business_license)}>
                        {doctorInfo.businessOrgFileName ? (
                          <S3.StyledSmallButton type="button" as="button" onClick={() => downloadFile(doctorInfo?.businessFileId, `${doctorInfo?.name}_${doctorInfo?.businessOrgFileName}`, t)}>
                            {doctorInfo?.businessOrgFileName}
                            <i className="ri-download-line"></i>
                          </S3.StyledSmallButton>
                        ) : (
                          <NotiMessage text={t(IK.file_empty)} mt0 />
                        )}
                      </FormControlsBox>

                      {/* 사업자 등록번호 */}
                      <FormControlsBox regular title={t(IK.business_number)}>
                        <S2.FormControls placeholder={t(IK.business_number)} defaultValue={doctorInfo.businessLicenseNumber} {...register("businessLicenseNumber")} />
                      </FormControlsBox>

                      {/* 할인율 */}
                      <FormControlsBox required regular title={t(IK.doctor_discount_grade)}>
                        {doctorAuth?.userPermission?.doctorDiscount ? (
                          <>
                            <S2.FormControls defaultValue={doctorInfo?.grade?.toUpperCase()} {...register("grade")} as="select" $select>
                              <option value="">{t(IK.select)}</option>
                              {Object.entries(doctorAuthGrade(doctorAuth?.isKorea)).map(([key, value]) => (
                                <option key={key} value={key}>
                                  {value === "DENTIS" ? t(IK.doctor_dentis) : value}
                                </option>
                              ))}
                            </S2.FormControls>
                            <ErrorMessage errors={errors} name="grade" />
                          </>
                        ) : (
                          doctorGrade[doctorInfo?.grade?.toUpperCase()]
                        )}
                      </FormControlsBox>

                      {/* 코인 */}
                      <FormControlsBox regular title={t(IK.coin)}>
                        <S2.FormControlsFlex>
                          {coinData}
                          {t(IK.coin)}
                          <S3.StyledSmallButton type="button" as="button" onClick={openCoinModal}>
                            {t(IK.coin_use_history)}
                          </S3.StyledSmallButton>
                        </S2.FormControlsFlex>
                      </FormControlsBox>

                      {/* 코인 충전, 차감 */}
                      {doctorAuth?.isKorea && doctorAuth?.userPermission?.doctorCoin && (
                        <FormControlsBox regular title={`${t(IK.coin_charge_deduction)}`}>
                          <S2.FormControlsColumn>
                            <S2.FormControlsFlex>
                              <S2.FormLineControls type="number" disabled={!isCoinCharge.isEdit} value={isCoinCharge.amount || ""} onChange={handleCoinAmount} />
                              {!isCoinCharge.isEdit ? (
                                <S3.StyledSmallButton type="button" as="button" onClick={() => setIsCoinCharge({ memo: "", amount: 0, isEdit: true })}>
                                  {t(IK.modify)}
                                </S3.StyledSmallButton>
                              ) : (
                                <S3.ButtonFlex $small>
                                  <S3.StyledSmallButton type="button" as="button" onClick={() => handleCoinCharge("CHARGE")}>
                                    {t(IK.pay_charging)}
                                  </S3.StyledSmallButton>
                                  <S3.StyledSmallButton $secondary type="button" as="button" onClick={() => handleCoinCharge("DEDUCTION")}>
                                    {t(IK.pay_using)}
                                  </S3.StyledSmallButton>
                                  <S3.StyledSmallButton $tertiary type="button" as="button" onClick={() => setIsCoinCharge({ memo: "", amount: "", isEdit: false })}>
                                    {t(IK.cancel)}
                                  </S3.StyledSmallButton>
                                </S3.ButtonFlex>
                              )}
                            </S2.FormControlsFlex>
                            {isCoinCharge.isEdit && <S2.FormControls as="textarea" $textarea placeholder={`${t(IK.coin_charge_deduction)} ${t(IK.memo)}`} onChange={handleCoinMemo} />}
                          </S2.FormControlsColumn>
                        </FormControlsBox>
                      )}

                      {/* 교육 영상 시청 */}
                      <FormControlsBox regular title={t(IK.video_call)}>
                        <S2.FormControlsColumn>
                          <S4.SelectionItem>
                            <S4.SelectionItemLabel>
                              <S4.SelectionItemInput
                                $checkbox
                                type="checkbox"
                                id="videoAll"
                                onChange={handleSelectAll}
                                checked={
                                  watchedVideos[0] === undefined
                                    ? doctorInfo.video1Yn === "Y" && doctorInfo.video2Yn === "Y" && doctorInfo.video3Yn === "Y" && doctorInfo.video4Yn === "Y"
                                    : watchedVideos.every((value) => value === "Y")
                                }
                              />
                              <S4.SelectionItemSpan>{t(IK.select_all)}</S4.SelectionItemSpan>
                            </S4.SelectionItemLabel>
                          </S4.SelectionItem>

                          <Controller
                            name="video1Yn"
                            control={control}
                            defaultValue={doctorInfo?.video1Yn || "N"}
                            render={({ field }) => (
                              <S4.SelectionItem>
                                <S4.SelectionItemLabel>
                                  <S4.SelectionItemInput $checkbox type="checkbox" id="video1Yn" {...field} checked={field.value === "Y"} onChange={(e) => handleSelect(e, "video1Yn")} />
                                  <S4.SelectionItemSpan>{t(IK.support_link_step_title1_2)}</S4.SelectionItemSpan>
                                </S4.SelectionItemLabel>
                              </S4.SelectionItem>
                            )}
                          />

                          <Controller
                            name="video2Yn"
                            control={control}
                            defaultValue={doctorInfo?.video2Yn || "N"}
                            render={({ field }) => (
                              <S4.SelectionItem>
                                <S4.SelectionItemLabel>
                                  <S4.SelectionItemInput $checkbox type="checkbox" id="video2Yn" {...field} checked={field.value === "Y"} onChange={(e) => handleSelect(e, "video2Yn")} />
                                  <S4.SelectionItemSpan>{t(IK.support_link_step_title2_2)}</S4.SelectionItemSpan>
                                </S4.SelectionItemLabel>
                              </S4.SelectionItem>
                            )}
                          />
                          <Controller
                            name="video3Yn"
                            control={control}
                            defaultValue={doctorInfo?.video3Yn || "N"}
                            render={({ field }) => (
                              <S4.SelectionItem>
                                <S4.SelectionItemLabel>
                                  <S4.SelectionItemInput $checkbox type="checkbox" id="video3Yn" {...field} checked={field.value === "Y"} onChange={(e) => handleSelect(e, "video3Yn")} />
                                  <S4.SelectionItemSpan>{t(IK.support_link_step_title3_2)}</S4.SelectionItemSpan>
                                </S4.SelectionItemLabel>
                              </S4.SelectionItem>
                            )}
                          />
                          <Controller
                            name="video4Yn"
                            control={control}
                            defaultValue={doctorInfo?.video4Yn || "N"}
                            render={({ field }) => (
                              <S4.SelectionItem>
                                <S4.SelectionItemLabel>
                                  <S4.SelectionItemInput $checkbox type="checkbox" id="video4Yn" {...field} checked={field.value === "Y"} onChange={(e) => handleSelect(e, "video4Yn")} />
                                  <S4.SelectionItemSpan>{t(IK.support_link_step_title4_2)}</S4.SelectionItemSpan>
                                </S4.SelectionItemLabel>
                              </S4.SelectionItem>
                            )}
                          />
                        </S2.FormControlsColumn>
                      </FormControlsBox>

                      {/* 승인 상태 */}
                      <FormControlsBox required regular title={t(IK.approve_status)}>
                        {doctorInfo?.status === "APPROVED" ? (
                          <>{doctorStatus[doctorInfo?.status]}</>
                        ) : (
                          <>
                            <S2.FormControls defaultValue={doctorInfo?.status} {...register("status")} as="select" $select>
                              <option value="">{t(IK.select)}</option>
                              <option value="APPROVED">{t(IK.status_apporve)}</option>
                              <option value="REJECT">{t(IK.status_reject)}</option>
                              <option value="UNAPPROVED">{t(IK.status_pending)}</option>
                            </S2.FormControls>
                            <ErrorMessage errors={errors} name="status" />
                          </>
                        )}
                      </FormControlsBox>
                    </S2.FormControlsContainer>
                  </li>
                </S2.FormCardList>

                <CardTail line>
                  <S3.ButtonLtBox>
                    {location?.state?.category ? (
                      <S3.StyledButton as="button" type="button" onClick={() => navigate(`/studyList${location?.state?.herf}`)}>
                        {t(IK.list)}
                      </S3.StyledButton>
                    ) : (
                      <S3.StyledButton as="button" type="button" onClick={() => navigate(`/doctorList${location?.state?.herf || "?page=1"}`)}>
                        {t(IK.list)}
                      </S3.StyledButton>
                    )}
                    {doctorAuth?.userPermission?.doctorDelete && (
                      <S3.StyledButton as="button" type="button" onClick={onDeleteDoctor}>
                        {t(IK.delete)}
                      </S3.StyledButton>
                    )}
                    <S3.StyledButton $secondary as="button" onClick={() => navigate(`/patientList?page=1&doctorId=${doctorId}`)}>
                      {t(IK.charge_patient_list)}
                    </S3.StyledButton>
                    <S3.StyledButton $primary as="button" type="submit">
                      {t(IK.save)}
                    </S3.StyledButton>
                  </S3.ButtonLtBox>
                </CardTail>
              </form>
            </S.CardInner>
          )}
        </S.CardCol>
      </S.CardRow>
      <CoinHistoryModal coinModal={coinModal} setCoinModal={setCoinModal} doctorId={doctorId} />
      <AddressEditModal
        addressData={addressModal.type === "ship" ? selecteShip : selecteBill}
        setSelecteShip={setSelecteShip}
        setSelecteBill={setSelecteBill}
        sameIdAddress={selecteShip.addressId === selecteBill.addressId}
        countryCode={doctorInfo?.countryCode}
        addressModal={addressModal}
        handleClose={handleCloseAddressModal}
      />
    </>
  );
};

export default DoctorView;
