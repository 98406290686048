import { useTranslation } from "react-i18next";
import * as S2 from "../../elements/Button/style/ButtonLayout.style";
import { IK } from "../../utils/i18n/keyStore";
import LoadingSpinner from "../Loading/LoadingSpinner";
import ModalLayout from "../Modal/ModalLayout";
import * as S3 from "../Modal/style/Modal.style";

export const RejectStudyModal = ({ openRejetModal, setOpenRejetModal, contents, isLoading }) => {
  const { t } = useTranslation(["translation"]);

  const closeModal = () => {
    setOpenRejetModal(false);
  };

  return (
    <ModalLayout open={openRejetModal} header={t(IK.companion_reason)} handleClose={closeModal}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="ck-content" dangerouslySetInnerHTML={{ __html: contents }} />
          <S3.ModalBoxBtn>
            <S2.ButtonFlex>
              <S2.StyledButton as="button" onClick={closeModal}>
                {t(IK.close)}
              </S2.StyledButton>
            </S2.ButtonFlex>
          </S3.ModalBoxBtn>
        </>
      )}
    </ModalLayout>
  );
};
