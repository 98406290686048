import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetRejectReason } from "../../../apis/patientApi";
import * as S2 from "../../../elements/Button/style/ButtonLayout.style";
import { IK } from "../../../utils/i18n/keyStore";
import { REJECT_REASON_LIST, studyTypeLabel } from "../../../utils/studyStatus";
import { RejectStudyModal } from "../RejectStudyModal";
import * as S from "../style/Study.style";

const RejectStudy = ({ studyInfo }) => {
  const { t } = useTranslation(["translation"]);

  const [openRejetModal, setOpenRejetModal] = useState(false);

  const { data, isLoading } = useGetRejectReason(studyInfo.patientId, studyInfo.statusId, openRejetModal);
  const contents = data?.data;

  return (
    <>
      <S.StudyHistoryCircle />
      <S.StudyHistoryItem>
        <S.StudyHistoryDepth1>
          <h3>
            [{t(IK[studyTypeLabel(studyInfo)])}] {t(IK.reject_study)} {studyInfo?.rejectReason && `${t(REJECT_REASON_LIST[studyInfo.rejectReason])}`}
          </h3>
          <S2.ButtonFlex>
            <S2.StyledSmallButton as="button" onClick={() => setOpenRejetModal(true)}>
              {t(IK.companion_reason)}
            </S2.StyledSmallButton>
          </S2.ButtonFlex>
          <span>
            {studyInfo?.createdDate} {studyInfo?.createdBy && studyInfo.writerShow && `(${studyInfo.createdBy})`}
          </span>
          {studyInfo?.modifiedDate && studyInfo?.createdDate !== studyInfo?.modifiedDate && (
            <span>
              {t(IK.modify)} : {studyInfo?.modifiedDate} {studyInfo?.modifiedBy && studyInfo.writerShow && `(${studyInfo.modifiedBy})`}
            </span>
          )}
        </S.StudyHistoryDepth1>
      </S.StudyHistoryItem>
      <RejectStudyModal openRejetModal={openRejetModal} setOpenRejetModal={setOpenRejetModal} contents={contents} isLoading={isLoading} />
    </>
  );
};

export default RejectStudy;
