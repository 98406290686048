import ReactDatePicker from "react-datepicker";
import { css, styled } from "styled-components";
import { useDatePickerLocale } from "../hooks/useDatePickerLocale";

export const DatePicker = ({ selected, handleDataChange, placeholderText, disabled, formControls, formLineControls, full }) => {
  const langInfo = useDatePickerLocale();

  return (
    <StDatePicker $formControls={formControls} $formLineControls={formLineControls} $full={full}>
      <ReactDatePicker
        locale={langInfo}
        placeholderText={placeholderText}
        selected={selected}
        onChange={handleDataChange}
        dateFormat="yyyy-MM-dd"
        disabled={disabled}
        showMonthDropdown
        showYearDropdown
      />
    </StDatePicker>
  );
};

export const MonthPicker = ({ selected, handleDataChange, placeholderText, formControls, full }) => {
  const langInfo = useDatePickerLocale();

  return (
    <StDatePicker $formControls={formControls} $full={full}>
      <ReactDatePicker locale={langInfo} placeholderText={placeholderText} selected={selected} onChange={handleDataChange} dateFormat="yyyy/MM" showMonthYearPicker />
    </StDatePicker>
  );
};

export const YearPicker = ({ selected, handleDataChange, placeholderText, formControls, full }) => {
  const langInfo = useDatePickerLocale();

  return (
    <StDatePicker $formControls={formControls} $full={full}>
      <ReactDatePicker locale={langInfo} placeholderText={placeholderText} selected={selected} onChange={handleDataChange} dateFormat="yyyy" showYearPicker />
    </StDatePicker>
  );
};

export const TimePicker = ({ selected, handleDataChange, placeholderText, formTimeControls, full, disabled }) => {
  const langInfo = useDatePickerLocale();

  return (
    <StDatePicker $formTimeControls={formTimeControls} $full={full}>
      <ReactDatePicker
        locale={langInfo}
        placeholderText={placeholderText}
        selected={selected}
        onChange={handleDataChange}
        showTimeSelect
        dateFormat="yyyy-MM-dd h:mm aa"
        timeIntervals={30}
        timeCaption="Time"
        disabled={disabled}
        showMonthDropdown
        showYearDropdown
      />
    </StDatePicker>
  );
};

const StDatePicker = styled.div`
  ${(props) =>
    props.$formControls &&
    css`
      .react-datepicker__input-container input {
        width: 129px;
        height: 46px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid ${(props) => props.theme.colors.gray200};
        background-color: ${(props) => props.theme.colors.white};
        &:disabled {
          background-color: ${(props) => props.theme.colors.gray300};
        }
      }
    `};

  ${(props) =>
    props.$formTimeControls &&
    css`
      .react-datepicker__input-container input {
        width: 170px;
        height: 46px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid ${(props) => props.theme.colors.gray200};
        background-color: ${(props) => props.theme.colors.white};
        &:disabled {
          background-color: ${(props) => props.theme.colors.gray300};
        }
      }
    `};

  ${(props) =>
    props.$formLineControls &&
    css`
      .react-datepicker__input-container input {
        width: 110px;
        padding: 5px 23px 5px 5px;
        border: 0;
        border-bottom: 1px solid ${(props) => props.theme.colors.gray400};
        &:disabled {
          background-color: ${(props) => props.theme.colors.white};
          color: ${(props) => props.theme.colors.gray600};
        }
      }
    `};

  ${(props) =>
    props.$full &&
    css`
      flex: 1;
      .react-datepicker-wrapper {
        display: block;
      }
      .react-datepicker__input-container input {
        width: 100%;
        flex: 1;
      }
    `};

  .react-datepicker {
    font-family: "Pretendard";
    font-size: 14px;
    border-color: ${(props) => props.theme.colors.gray200};
  }

  .react-datepicker__navigation {
    top: 14px;
  }

  .react-datepicker__navigation--previous {
    left: 10px;
  }

  .react-datepicker__navigation--next {
    right: 10px;
  }

  .react-datepicker__navigation--next--with-time {
    right: 96px;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    top: 9px;
    border-color: ${(props) => props.theme.colors.white};
  }

  .react-datepicker__navigation:hover *::before {
    border-color: rgba(255, 255, 255, 0.5);
  }

  .react-datepicker__triangle {
    left: 50px !important;
    transform: none !important;
    margin-left: 0;
  }

  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
    z-index: 1;
  }

  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
    border-top-color: ${(props) => props.theme.colors.gray200};
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: 0;
    z-index: 1;
    border-bottom-color: ${(props) => props.theme.colors.blue400};
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    opacity: 0;
  }

  .react-datepicker__header {
    padding: 15px 0;
    background-color: ${(props) => props.theme.colors.blue400};
    border: 0;
    color: ${(props) => props.theme.colors.white};
    font-size: 18px;
  }

  .react-datepicker__header--time {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }

  .react-datepicker-time__header {
    color: ${(props) => props.theme.colors.white};
  }

  .react-datepicker__current-month {
    font-size: 18px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.white};
  }

  .react-datepicker__header__dropdown {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 10px;
    margin-top: 8px;
  }

  .react-datepicker__month-dropdown-container,
  .react-datepicker__year-dropdown-container {
    margin: 0;
  }

  .react-datepicker__year-read-view,
  .react-datepicker__month-read-view {
    padding: 3px 31px 3px 10px;
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow {
    right: 10px;
  }

  .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow {
    border-top-color: ${(props) => props.theme.colors.white};
  }

  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown {
    background-color: ${(props) => props.theme.colors.blue400};
    border-color: #e9ebf1;
  }

  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  .react-datepicker__year-option a {
    display: flex;
    justify-content: center;
    top: 0;
  }

  .react-datepicker__year-option a:before {
    border-color: ${(props) => props.theme.colors.white};
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    width: 9px;
  }

  .react-datepicker__year-option:first-child .react-datepicker__navigation--years:before {
    top: 14px;
    transform: rotate(315deg);
  }

  .react-datepicker__year-option:last-child .react-datepicker__navigation--years:before {
    bottom: 14px;
    transform: rotate(135deg);
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 34px;
    line-height: 34px;
    margin: 3px;
    font-size: 14px;
  }

  .react-datepicker__day-name {
    color: ${(props) => props.theme.colors.white};
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: ${(props) => props.theme.colors.blue400};
    border-radius: 5px;
  }

  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    background-color: rgba(55, 125, 255, 0.8);
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: ${(props) => props.theme.colors.blue400};
    color: ${(props) => props.theme.colors.white};
  }

  .react-datepicker__year-wrapper {
    justify-content: center;
  }

  .react-datepicker__year .react-datepicker__year-text {
    width: 46px;
    margin: 5px;
    padding: 6px;
  }

  .react-datepicker__time-container .react-datepicker__time {
    border-left: 1px solid ${(props) => props.theme.colors.gray200};
  }

  .react-datepicker__time-container {
    width: auto;
    border: 0;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: auto;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    width: auto;
    padding: 5px;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    border-radius: 0.3rem;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: ${(props) => props.theme.colors.blue400};
  }
`;
